import React from "react";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import ReactHtmlParser from "react-html-parser";

@withI18next(["common"])
@page
class Maintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <link href="/file/css/maintenance.css" rel="stylesheet" />
        {ReactHtmlParser(this.props.t("jumperrwd.maintenance.content"))}
      </>
    );
  }
}

export default Maintenance;
